import * as process from "process"

enum BgvMessageType {
  BGV_JWT_MESSAGE_REQUEST,
  BGV_JWT_MESSAGE_RESPONSE,
}

interface BgvJwtMessage {
  type: BgvMessageType
  jwt?: string
}

export function getBgvJwt(): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const messageListener = (event: MessageEvent<BgvJwtMessage>): void => {
      if (event.origin !== process.env.VITE_BGV_URL) {
        return
      }

      if (event.data.type !== BgvMessageType.BGV_JWT_MESSAGE_RESPONSE) {
        return
      }

      window.removeEventListener("message", messageListener, false)

      resolve(event.data.jwt)
    }

    window.addEventListener("message", messageListener, false)
    window.top.postMessage(
      {
        type: BgvMessageType.BGV_JWT_MESSAGE_REQUEST,
      },
      process.env.VITE_BGV_URL as string
    )
  })
}
